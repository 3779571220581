import {
  ChartConfiguration,
  ChartData,
  ChartDataset,
  ChartType,
  DefaultDataPoint,
} from 'chart.js';
import { IGetCurrentCamPoint, IVariableToDisplay } from '../api/api-sdk';

export enum RecognitionType {
  flowers = 'flowers',
  leaves = 'leaves',
}

export enum CameraVariableType {
  temperature = 'temperature',
  stomata = 'stomata',
  vpd = 'vpd',
}

export enum DashboardCacheType {
  readings = 'readings',
  points = 'points',
  customPoints = 'customPoints',
  images = 'images',
  recognitionReadings = 'recognitionReadings',
  recognitionAreas = 'recognitionAreas',
}

export enum Uom {
  celsius = '°C',
  kPa = 'kPa',
  stomata = 'ST',
  biomass = 'Biomass',
}

export enum VariableDisplayName {
  temperature = 'temperature',
  vpd = 'vpd',
}

export interface IChartUomConfig {
  minValue?: number;
  maxValue?: number;
}

export interface IChartLegendDataset {
  name: string;
  variable: string;
  color: string;
  borderDash: number[] | undefined;
}

export interface IChartLegendDatasetGroup {
  variable: string;
  datasets: IChartLegendDataset[];
}

export interface IChartLegendItem {
  uom: string;
  datasetGroups: IChartLegendDatasetGroup[];
}

export interface IChartMetadata {
  legend: IChartLegendItem[];
}

export interface IChartData {
  data: ISigrowChartConfiguration['data'];
  options: ChartConfiguration['options'];
  metadata: IChartMetadata | undefined;
}

export interface IChatGenerationResult {
  data: IChartData;
  colorsMap: Map<string, string>;
}

export interface ICameraRecognitionConfig {
  cameraId: number;
  recognitionType: RecognitionType;
}

export interface IDevice {
  name: string;
  remote_id: number;
  thermal_camera_id: number;
}

export interface IConfiguredDevice extends IDevice {
  flower_recognition: string;
  allowed_variables: number[];
  timestamp_unix: number;
}

export interface ICameraVariable {
  name: string;
  type: CameraVariableType;
  uom: Uom;
  valuePath?: string;
}

export interface ICustomPoint {
  cameraId: number;
  clientX: number;
  clientY: number;
  serverX: number;
  serverY: number;
}

export type Selectable<T> = T & { selected: boolean };

export type SigrowDataset<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
> = ChartDataset<TType, TData> & {
  deviceId: number;
  uom: string;
  variableName: string;
};

export type CameraDataset<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
> = ChartDataset<TType, TData> & { cameraId: number };

export interface ISigrowChartData<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown,
> extends ChartData<TType, TData, TLabel> {
  datasets: SigrowDataset<TType, TData>[];
}

export interface ISigrowChartConfiguration<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown,
> extends ChartConfiguration<TType, TData, TLabel> {
  data: ISigrowChartData<TType, TData, TLabel>;
}

export interface IDashboardConfig {
  devices: IDevice[];
  variables: IVariableToDisplay[];
  // TODO: refactor this, merge rec and point vars
  recognitionVariables: ICameraVariable[];
  pointVariables: ICameraVariable[];
  points: IGetCurrentCamPoint[];
  customPoints: ICustomPoint[];
  recognitions: ICameraRecognitionConfig[];
  chartUomConfigs: {};
}

export interface ISavedDashboardConfig {
  id: string;
  name: string;
  locationId: number;
  dateRangeName: string;
  default: boolean;
  config: IDashboardConfig;
}

export interface IChartCommentConfig {
  dashboardConfig: IDashboardConfig;
  startTimestamp: number;
  endTimestamp: number;
}

export interface ITooltip {
  title: string;
  variable: string;
  uom: string;
  color: string;
  x: number;
  y: number;
}

export interface IRTRChartData {
  data: {
    labels: string[];
    datasets: CameraDataset[];
  };
  options: ChartConfiguration['options'];
}

export const recognitionVariables: ICameraVariable[] = [
  {
    name: 'cameraTemperature',
    type: CameraVariableType.temperature,
    uom: Uom.celsius,
  },
  { name: 'cameraVPD', type: CameraVariableType.vpd, uom: Uom.kPa },
];

export const pointVariables: ICameraVariable[] = [
  {
    // TODO: convert this to enum
    name: 'pointTemperature',
    type: CameraVariableType.temperature,
    uom: Uom.celsius,
    valuePath: 'readings.temperature',
  },
  {
    name: 'pointVPD',
    type: CameraVariableType.vpd,
    uom: Uom.kPa,
    valuePath: 'readings.vapour_pressure_difference',
  },
  {
    name: 'pointStomata',
    type: CameraVariableType.stomata,
    uom: Uom.stomata,
    valuePath: 'readings.stomata',
  },
];

export const emptyChartData: IChartData = {
  data: { datasets: [] },
  options: {},
  metadata: {
    legend: [],
  },
};
