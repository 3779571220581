import { IDashboardState } from '../components/admin/dashboard/state/dashboard.feature';
import { IDashboardConfig } from '../model/dashboard';

export class DashboardUtils {
  static getDashboardConfigFromState(state: IDashboardState): IDashboardConfig {
    return {
      customPoints: state.customPoints,
      devices: state.devices,
      points: state.points,
      recognitions: state.recognitions,
      recognitionVariables: state.recognitionVariables,
      pointVariables: state.pointVariables,
      variables: state.variables,
      chartUomConfigs: state.chartUomConfigs,
    };
  }
}
